import React, {useContext, useEffect} from 'react'
import {Checkbox, Container, TextField, Typography, FormControlLabel} from '@material-ui/core'
import clsx from 'clsx'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import * as yup from 'yup'
import {FormikProvider, useFormik} from 'formik'
import {Link} from 'react-router-dom'
import I18nContext from '../../../services/I18n/I18nContext'
import useStyles from './FormStyles'
import {ActionData, QuoteAction, QuoteContext} from '../../../services/quote/QuoteProvider'
import DeliveryAddressForm from './components/DeliveryAddressForm'
import {getGoogleTagItems} from '../../../utils/helpers/helpers'
import {currency} from '../Services/components/ServiceItem'
import {useIsMount} from '../../../utils/hooks/useIsMount'

const phoneRegExp = /^(\+)(3)(8)(6)\d{6,11}?$/

const formInit = {
    oib: '',
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    address: '',
    place: '',
    zip: '',
    fiscalNumber: '',
    deliveryOib: '',
    deliveryFirstName: '',
    deliveryLastName: '',
    deliveryCompany: '',
    deliveryPhone: '',
    deliveryEmail: '',
    deliveryAddress: '',
    deliveryPlace: '',
    deliveryZip: '',
    partnerCode: '',
    deliveryAddressChecked: false,
    terms: true,
    informed: true
}

const Form = () => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)
    const {dispatch, quote} = useContext(QuoteContext)
    //const [showPartnerInput, setShowPartnerInput] = useState(false)

    const isMount = useIsMount();

    const formSchema = yup.object().shape({
        oib: yup.number().test('len', 'Vsebovati mora 8 znakov', (val: any) => !!val && val.toString().length === 8).required(translate('requiredFiled')),
        firstName: yup.string().required(translate('requiredFiled')),
        lastName: yup.string().required(translate('requiredFiled')),
        company: yup.string().required(translate('requiredFiled')),
        phone: yup.string().min(9, translate('phoneValidationMin')).matches(phoneRegExp, translate('phoneValidation')),
        email: yup.string().email(translate('invalidEmail')).required(translate('requiredFiled')),
        address: yup.string().required(translate('requiredFiled')),
        place: yup.string().required(translate('requiredFiled')),
        fiscalNumber: yup.string().test( 'no-SI', 'SI je obvezen',
            (value, context) => {
                return (value && value.length >= 1) ? (context.originalValue &&
                    context.originalValue.startsWith("SI")) : true
            }
        ).test('len', 'Vsebovati mora 10 znakov', (val: any) => (val && val.length >= 1) ?  val.toString().length === 10 : true),
        zip: yup.number().required(translate('requiredFiled')).test('len', 'Vsebovati mora 4 znakov', (val: any) => !!val && val.toString().length === 4).typeError(translate('requiredFiled')),
        deliveryAddressChecked: yup.bool(),
        partnerCode: yup.string(),
        terms: yup.bool().oneOf([true], 'Must agree to something'),
        informed: yup.bool(),
        deliveryFirstName: yup.string().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled'))
        }),
        deliveryLastName: yup.string().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled'))
        }),
        deliveryPhone: yup.number().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled')).min(2, translate('requiredFiled')),
        }),
        deliveryAddress: yup.string().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled'))
        }),
        deliveryPlace: yup.string().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled'))
        }),
        deliveryZip: yup.number().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled')).test('len', 'Vsebovati mora 4 znakov', (val: any) => !!val && val.toString().length === 4).typeError(translate('requiredFiled')),
        }),
    })

    const formik = useFormik({
        initialValues: quote.form || formInit,
        validationSchema: formSchema,
        onSubmit: values => {
            console.log(values)
        }
    })

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isValid,
        dirty,
        handleBlur
    } = formik

    useEffect(() => {
        if (!isMount) return;
        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: currency,
                value: quote.totalFixed === 0 ? quote.totalMonthly : quote.totalFixed,
                items: getGoogleTagItems(quote)
            }
        })
        // eslint-disable-next-line
    }, [isMount])

    useEffect(() => {
        dispatch({
            type: QuoteAction.setForm,
            data: {form: values} as ActionData
        })
        // eslint-disable-next-line
    }, [values])


    useEffect(() => {
        dispatch({
            type: QuoteAction.setIsFormValid,
            data: {isFormValid: (quote.formDirty ? isValid : (dirty && isValid))} as ActionData
        })
        // eslint-disable-next-line
    }, [isValid])

    return (
        <Container className={classes.root}>
            <div className={classes.header}>
                <Link to="/" className={classes.backButton}>
                    <ArrowBackIosIcon className={classes.backButtonIcon}/>
                    {translate('back')}
                </Link>
                <h1 className={classes.rootTitle}>{translate('formTitle')}</h1>
            </div>

            <div className={classes.card}>
                <Typography className={classes.h3} variant="h3">{translate('formSubtitle')}</Typography>
                <FormikProvider value={formik} key="stef">
                    <form onSubmit={handleSubmit} name="quoteForm" className={classes.formWrapper}>
                        <div className={classes.row}>
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="oib"
                                onBlur={handleBlur}
                                label={`${translate('oib')} *`}
                                placeholder={translate('oib')}
                                value={values.oib}
                                onChange={handleChange}
                                error={touched.oib && (Boolean(errors.oib))}
                                helperText={touched.oib && errors.oib}
                            />
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="fiscalNumber"
                                onBlur={handleBlur}
                                label={`${translate('fiscalNumber')}`}
                                placeholder={translate('fiscalNumber')}
                                value={values.fiscalNumber}
                                onChange={handleChange}
                                error={touched.fiscalNumber && (Boolean(errors.fiscalNumber))}
                                helperText={touched.fiscalNumber && errors.fiscalNumber}
                            />
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="firstName"
                                onBlur={handleBlur}
                                label={`${translate('firstName')} *`}
                                placeholder={translate('firstName')}
                                value={values.firstName}
                                onChange={handleChange}
                                error={touched.firstName && Boolean(errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                            />
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="lastName"
                                label={`${translate('lastName')} *`}
                                placeholder={translate('lastName')}
                                onBlur={handleBlur}
                                value={values.lastName}
                                onChange={handleChange}
                                error={touched.lastName && Boolean(errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                className={clsx(classes.input, classes.doubleInput)}
                                variant="outlined"
                                name="company"
                                onBlur={handleBlur}
                                label={`${translate('company')} *`}
                                placeholder={translate('company')}
                                value={values.company}
                                onChange={handleChange}
                                error={touched.company && Boolean(errors.company)}
                                helperText={touched.company && errors.company}
                            />
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="phone"
                                placeholder={translate('phonePlaceholder')}
                                onBlur={handleBlur}
                                label={`${translate('phone')} *`}
                                value={values.phone}
                                onChange={handleChange}
                                error={Boolean(errors.phone)}
                                helperText={errors.phone}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="email"
                                onBlur={handleBlur}
                                label={`${translate('email')} *`}
                                placeholder={translate('email')}
                                value={values.email}
                                onChange={handleChange}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                            />
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="address"
                                onBlur={handleBlur}
                                label={`${translate('address')} *`}
                                placeholder={translate('address')}
                                value={values.address}
                                onChange={handleChange}
                                error={touched.address && Boolean(errors.address)}
                                helperText={touched.address && errors.address}
                            />
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="place"
                                onBlur={handleBlur}
                                label={`${translate('place')} *`}
                                placeholder={translate('place')}
                                value={values.place}
                                onChange={handleChange}
                                error={touched.place && Boolean(errors.place)}
                                helperText={touched.place && errors.place}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                name="zip"
                                onBlur={handleBlur}
                                inputProps={{
                                    minLength: 2
                                }}
                                label={`${translate('zip')} *`}
                                placeholder={translate('zip')}
                                value={values.zip}
                                onChange={handleChange}
                                error={touched.zip && Boolean(errors.zip)}
                                helperText={touched.zip && errors.zip}
                            />
                        </div>
                        <div className={classes.checkboxWrapper} style={{marginTop: '30px', paddingLeft: 10}}>
                            <FormControlLabel
                                control={
                                    <Checkbox value={values.deliveryAddressChecked}
                                              onChange={handleChange}
                                              name="deliveryAddressChecked"
                                              defaultChecked={values.deliveryAddressChecked}
                                              color="primary"/>
                                }
                                classes={{label: classes.labelBold}}
                                label={translate('deliveryAddress')}
                            />
                        </div>
                        {values.deliveryAddressChecked && (
                            <div className={classes.deliveryAddressFormWrapper}>
                                <DeliveryAddressForm formik={formik}/>
                            </div>
                        )}
                        <Typography className={classes.h3} variant="h3">{translate('consent')}</Typography>
                        <div className={classes.checkboxWrapper}>
                            <Checkbox value={values.terms} onChange={handleChange} name="terms" color="primary"
                                      defaultChecked={true}/>
                            <div className={classes.fullRow}>
                                <Typography className={classes.label}>{translate('accept')}</Typography>
                                <a href="https://www.marketino.si/pogoji/" className={classes.boldLink} target="_blank"
                                   rel="noreferrer">
                                    {translate('terms')}
                                </a>
                                <Typography className={classes.labelRequired}>*</Typography>
                            </div>
                        </div>
                        <div className={classes.checkboxWrapper} style={{paddingLeft: 10}}>
                            <FormControlLabel
                                control={
                                    <Checkbox value={values.informed}
                                              defaultChecked={true}
                                              onChange={handleChange}
                                              name="informed"
                                              color="primary"/>
                                }
                                label={translate('informed')}
                            />
                        </div>
                        <div className={classes.fullRow}>
                            <Typography className={classes.label}>
                                {translate('privacyInfo')}
                                <span>
                                    <a href="https://www.marketino.si/pravilnik-o-zasebnosti-podatkov/"
                                       className={classes.link} target="_blank" rel="noreferrer">
                                        {translate('here')}
                                    </a>
                                </span>
                            </Typography>
                        </div>
                        {/*<Typography className={classes.h3} variant="h3">{translate('partners')}</Typography>
                        <div className={classes.partnerWrapper}>
                            <div className={classes.row}>
                                <Typography className={classes.label}>{translate('partnerMessage')}</Typography>
                                <Typography className={clsx(classes.link, classes.partnerLink)}
                                            onClick={() => setShowPartnerInput(true)}>{translate('here')}</Typography>
                            </div>
                            {showPartnerInput && (
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    name="partnerCode"
                                    onBlur={handleBlur}
                                    label={translate('partnerCode')}
                                    value={values.partnerCode}
                                    onChange={handleChange}
                                    error={touched.partnerCode && Boolean(errors.partnerCode)}
                                    helperText={touched.partnerCode && errors.partnerCode}
                                />
                            )}
                        </div>*/}
                    </form>
                </FormikProvider>
                <div className={classes.infoWrapper}>
                    <Typography className={classes.infoMessage}>
                        {translate('formInfoMessage')}
                    </Typography>
                </div>
            </div>
        </Container>
    )
}

export default Form

import React, {useContext} from 'react'
import Typography from '@material-ui/core/Typography'
import useStyles from './SetupInfoPopupStyles'
import I18nContext from '../../../services/I18n/I18nContext'
import setupIcon1 from '../../../style/images/setup-icon-1.png'
import setupIcon2 from '../../../style/images/setup-icon-2.png'
import setupIcon3 from '../../../style/images/setup-icon-3.png'
import setupIcon4 from '../../../style/images/setup-icon-4.png'
import setupIcon5 from '../../../style/images/setup-icon-5.png'
import CustomButton from '../CustomButton/CustomButton'
import {ActionData} from '../../../services/quote/QuoteProvider'
import IService from '../../../interfaces/IService'
import CloseIcon from '@material-ui/icons/Close'

interface ISetupNote {
    label: string
    icon: string
}

interface ICustomButtonProps {
    option: IService
    addItem: (item: ActionData) => void
    close: () => void
}

const setupNotes = [
    {
        label: 'navodila za pridobitev certifikata za davčno potrjevanje računovuvoz certifikata v Marketino blagajno',
        icon: setupIcon1
    },
    {
        label: 'pripravo Internega akta',
        icon: setupIcon2
    },
    {
        label: 'vnos vašega cenika in prilagajanje nastavitev sistema',
        icon: setupIcon3
    },
    {
        label: 'samodejni prenos podatkov, če prehajate z drugih rešitev za davčno potrjevanje računov',
        icon: setupIcon4
    },
    {
        label: 'podešavanje ostalih postavki blagajničkog sustava.',
        icon: setupIcon5
    },
]

const SetupInfoPopup: React.FunctionComponent<ICustomButtonProps> = props => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)
    const {option} = props

    const setSetup = () => {
        if (!option) return
        props.addItem({...option, quantity: 1} as ActionData)
        props.close()
    }

    return (
        <div className={classes.root}>
            <CloseIcon className="close-icon" onClick={props.close}/>
            <h1 className={classes.rootTitle}>{translate('setupInfoPopupTitle')}</h1>
            <h5 className={classes.rootSubTitle}>{translate('setupInfoPopupSubTitle')}</h5>
            <div className={classes.notes}>
                {
                    (setupNotes as ISetupNote[]).map((note, index) => {
                        return (
                            <div className={classes.note} key={`setupNote${index}`}>
                                <img src={note.icon} alt="setupNote" style={{height: '20px', width: '20px'}}/>
                                <Typography className={classes.noteLabel}>{note.label}</Typography>
                            </div>
                        )
                    })
                }
            </div>
            <Typography className={classes.priceInfo}>
                {translate('setupPrice')} <span>{option.price} €</span>
            </Typography>
            <CustomButton text={translate('setSetup')}
                          onClick={setSetup}
                          className={classes.button}
                          textClass={classes.buttonText}
            />
            <Typography className={classes.closePopupButton} onClick={props.close}>
                {translate('closePopup')}
            </Typography>
        </div>
    )
}

export default SetupInfoPopup

import React, {useContext} from 'react'
import {TextField} from '@material-ui/core'
import I18nContext from '../../../../services/I18n/I18nContext'
import useStyles from '../FormStyles'

interface IDeliveryAddressFormProps {
    formik: any
}

const DeliveryAddressForm = (props: IDeliveryAddressFormProps) => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)

    const {
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
    } = props.formik

    return (
        <>
            <div className={classes.row}>
                <TextField
                    className={classes.input}
                    variant="outlined"
                    name="deliveryFirstName"
                    onBlur={handleBlur}
                    label={`${translate('firstName')} *`}
                    placeholder={translate('firstName')}
                    value={values.deliveryFirstName}
                    onChange={handleChange}
                    error={touched.deliveryFirstName && Boolean(errors.deliveryFirstName)}
                    helperText={touched.deliveryFirstName && errors.deliveryFirstName}
                />
                <TextField
                    className={classes.input}
                    variant="outlined"
                    name="deliveryLastName"
                    onBlur={handleBlur}
                    label={`${translate('lastName')} *`}
                    placeholder={translate('lastName')}
                    value={values.deliveryLastName}
                    onChange={handleChange}
                    error={touched.deliveryLastName && Boolean(errors.deliveryLastName)}
                    helperText={touched.deliveryLastName && errors.deliveryLastName}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    className={classes.input}
                    variant="outlined"
                    name="deliveryPhone"
                    inputProps={{
                        minLength: 2
                    }}
                    onBlur={handleBlur}
                    label={`${translate('phone')} *`}
                    placeholder={translate('phone')}
                    value={values.deliveryPhone}
                    onChange={handleChange}
                    error={touched.deliveryPhone && Boolean(errors.deliveryPhone)}
                    helperText={touched.deliveryPhone && errors.deliveryPhone}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    className={classes.input}
                    variant="outlined"
                    name="deliveryAddress"
                    onBlur={handleBlur}
                    label={`${translate('address')} *`}
                    placeholder={translate('address')}
                    value={values.deliveryAddress}
                    onChange={handleChange}
                    error={touched.deliveryAddress && Boolean(errors.deliveryAddress)}
                    helperText={touched.deliveryAddress && errors.deliveryAddress}
                />
                <TextField
                    className={classes.input}
                    variant="outlined"
                    name="deliveryPlace"
                    onBlur={handleBlur}
                    label={`${translate('place')} *`}
                    placeholder={translate('place')}
                    value={values.deliveryPlace}
                    onChange={handleChange}
                    error={touched.deliveryPlace && Boolean(errors.deliveryPlace)}
                    helperText={touched.deliveryPlace && errors.deliveryPlace}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    className={classes.input}
                    variant="outlined"
                    name="deliveryZip"
                    inputProps={{
                        minLength: 2
                    }}
                    onBlur={handleBlur}
                    label={`${translate('zip')} *`}
                    placeholder={translate('zip')}
                    value={values.deliveryZip}
                    onChange={handleChange}
                    error={touched.deliveryZip && Boolean(errors.deliveryZip)}
                    helperText={touched.deliveryZip && errors.deliveryZip}
                />
            </div>
        </>
    )
}

export default DeliveryAddressForm

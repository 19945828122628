import {createStyles, makeStyles, Theme} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#F8F8F8',
            paddingTop: '60px',
        },
        title: {
            fontSize: '2rem',
            textAlign: 'center',
            fontFamily: 'RobotoBold',
            marginBottom: '30px'
        },
        card: {
            border: '1px solid #E5E5E5',
            borderRadius: '4px',
            backgroundColor: 'white',
            boxShadow: '0 2px 5px 1px rgba(0,0,0,0.1)',
            padding: '30px',
            textAlign: 'center'
        },
        button: {
            color: theme.palette.primary.main,
            fontFamily: 'RobotoBold',
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        buttonIcon: {
            fontSize: '1rem',
            margin: '0 5px'
        },
        successIcon: {
            color: '#92278F',
            fontSize: '4rem',
            marginBottom: '25px'
        },
        failIcon: {
            color: '#D0021B',
            fontSize: '4rem',
            marginBottom: '25px'
        },
        message: {
            fontSize: '1.25rem',
            marginBottom: '30px'
        }
    }),
)

export default useStyles

import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {smBreakpoint} from '../../../utils/constants/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            position: 'fixed',
            zIndex: 11,
            background: 'white',
            left: 0,
            top: 0,
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '45px 15px',
        },
        rootTitle: {
            fontFamily: 'RobotoBold',
            textAlign: 'center',
            marginBottom: '40px',
            marginTop: '0',

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '100%',
                textAlign: 'left',
                fontSize: '1.625rem'
            },
        },
        rootSubTitle: {
            fontFamily: 'RobotoBold',
            textAlign: 'center',
            fontSize: '1.25rem',
            margin: '0',

            [theme.breakpoints.down(smBreakpoint)]: {
                textAlign: 'center',
                fontSize: '1.125rem'
            },
        },
        notes: {
            marginTop: '35px'
        },
        note: {
            display: 'flex',
            marginBottom: 20,
            alignItems: 'center'
        },
        noteLabel: {
            marginLeft: '25px'
        },
        priceInfo: {
            marginTop: '20px',
            color: '#999999',
            textAlign: 'center',

            '& span': {
                color: theme.palette.primary.main,
            }
        },
        button: {
            marginTop: '25px',
            transition: 'width .3s ease-in-out',
            backgroundColor: theme.palette.primary.main,
            width: '290px',

            '&:hover, &:active, &:focus': {
                backgroundColor: theme.palette.primary.main,

                [`&.Mui-disabled`]: {
                    backgroundColor: theme.palette.primary.main,
                },
            },

            '&:disabled': {
                opacity: 0.5
            },
        },
        buttonText: {
            color: 'white',
        },
        closePopupButton: {
            cursor: 'pointer',
            marginTop: '25px',
            fontSize: '0.875rem',
            color: theme.palette.primary.main,
        }
    }),
)

export default useStyles

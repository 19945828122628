import axios from 'axios'

const saveQuoteApiUrl = 'https://quotes2.hub.marketino.com/api/web-quote/si'

const saveQuote = async (quote: any) => {

    const resp = await axios.post(saveQuoteApiUrl, quote, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json'
        },
    });

    if (!resp) {
        throw new Error('Error with applying quote')
    }

    return resp.data
};

export {saveQuote}

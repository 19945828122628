import image1 from '../../../style/images/img-01.png'
import image2 from '../../../style/images/img-02.png'
import image3 from '../../../style/images/img-03.png'
import image1Mini from '../../../style/images/img-01-mini.png'
import image2Mini from '../../../style/images/img-02-mini.png'
import image3Mini from '../../../style/images/img-03-mini.png'

const images = [image1, image2, image3]
const imagesMini = [image1Mini, image2Mini, image3Mini]

export {images, imagesMini}

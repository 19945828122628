import {
    ThemeOptions,
} from '@material-ui/core/styles/createMuiTheme'
import {PaletteColor} from '@material-ui/core/styles/createPalette'

const primaryColorPalette: PaletteColor = {
    light: '',
    main: '#92278F',
    dark: '',
    contrastText: '',
}

const secondaryColorPaletthe: PaletteColor = {
    light: '#CCCCCC',
    main: '#333333',
    dark: '',
    contrastText: '',
}

const errorColorPalette: PaletteColor = {
    dark: '#B00020',
    main: '#d60027',
    light: '#ff002e',
    contrastText: '#ffffff',
}

const actionColorPaletthe = {
    disabled: '#ffffff'
}

export const GlobalTheme: ThemeOptions = {
    props: {
        MuiButtonBase: {
            disableRipple: true, // No more ripple, on the whole application 💣! jer je sporo na Sumniju V1s
        },
        MuiContainer: {
            maxWidth: false
        }
    },
    palette: {
        primary: primaryColorPalette,
        secondary: secondaryColorPaletthe,
        error: errorColorPalette,
        action: actionColorPaletthe,
        background: {
            default: '#F8F8F8',
        },
    },
    typography: {
        fontSize: 16,
        overline: {letterSpacing: '2px'},
        fontFamily: [
            'Roboto',
            'RobotoBold',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    },
    overrides: {
        MuiTypography: {
            root: {
                '&.formErrorMessage': {
                    color: 'red',
                },
            },
            h1: {
                fontSize: '2rem',
                textAlign: 'center',
                fontFamily: 'RobotoBold',
                marginTop: '30px'
            },
            h6: {
                fontSize: '1rem',
                fontWeight: 400,
            },
            subtitle1: {
                fontSize: '1rem',
                letterSpacing: '0.15px',
                lineHeight: '1.7rem'
            },
            body1: {
                fontSize: '1rem',
                lineHeight: '1.5rem'

            },
            body2: {
                fontSize: '0.875rem',
                letterSpacing: '0.25px',
                lineHeight: '1.25rem'
            }
        },
        MuiInputBase: {
            input: {
                paddingBottom: '8px',
            },
        },
        MuiContainer: {
            root: {
                maxWidth: '1480px',
            },
        },

        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: '#F4E9F3',
                    '&:hover': {
                        backgroundColor: '#F4E9F3',
                    },
                },
            },
            button: {
                '&:hover': {
                    backgroundColor: '#F4E9F3',
                },
            },
        },

    },
}

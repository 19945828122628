import {Quote} from '../../services/quote/QuoteProvider';

const validateISO7064 = (oibArray: any) => {
    const controlDigit = oibArray.pop();
    let value = oibArray.reduce((prev: any, next: any) => {
        let check = prev + next;
        check %= 10;

        if (check === 0) {
            check = 10;
        }

        check *= 2;
        check %= 11;

        return check;
    }, 10);

    const checksum = (11 - value === 10) ? 0 : (11 - value);

    return checksum === controlDigit;
}

const stringToNumberArray = (string: any) => {
    return Array.prototype.slice.call(string).map((digit) => {
        return parseInt(digit, 10);
    });
}

export const validate = (oibNumber: any) => {
    const number = oibNumber.toString();
    const oibDigits = stringToNumberArray(number);

    const lengthVal = oibDigits.length === 11;
    const intVal = parseInt(number, 10);
    const isoVal = validateISO7064(oibDigits);

    return lengthVal && Boolean(intVal) && isoVal;
}

export const getGoogleTagItems = (quote: Quote) => {
    let tagItems = []
    if (quote.items.length < 1) {
        tagItems.push({
            item_id: 0,
            item_name: 'Usluga Neosalon fiskalne blagajne'
        })
    } else {
        quote.items.forEach((item: any) => {
            if (item.id === 'basicService1') {
                tagItems.push({
                    item_id: 0,
                    item_name: 'Usluga Neosalon fiskalne blagajne'
                });
            } else {
                tagItems.push({
                    item_id: item.auto_code,
                    item_name: item.title
                })
            }
        })
    }

    return tagItems;
}

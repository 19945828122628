import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {mdBreakpoint, smBreakpoint, xsBreakpoint} from '../../../utils/constants/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            boxShadow: '0 -2px 5px 1px rgba(0,0,0,0.1)',
            width: '100%',
            position: 'fixed',
            left: 0,
            bottom: 0,
            zIndex: 10,

            // Footer mobile/tablet styles

            [theme.breakpoints.down(smBreakpoint)]: {
                transition: 'bottom .3s ease',
                height: '630px',
                bottom: '-500px'
            },

            '&.footer-active': {
                bottom: 0,

                '& $actionsWrapper': {
                    width: '100%',
                    marginBottom: '30px'
                },

                '& $paymentTitle': {
                    display: 'block',
                    width: '80%',
                    textAlign: 'center'
                },

                '& $couponCodeWrapper': {
                    width: '100%',
                    marginBottom: '15px',
                    display: 'inherit'
                },

                '& $couponInputButtonWrapper': {
                    width: '100%',
                    display: 'flex'
                },

                '& $couponInput': {
                    width: 'calc(100% - 90px)',
                    display: 'block'
                },

                '& $footerContainer': {
                    flexDirection: 'column'
                },

                '& $calculationWrapper': {
                    width: '100%'
                },

                '& $fixedCalculationWrapper': {
                    marginBottom: '15px'
                },

                '& $monthlyCalculationWrapper': {
                    marginBottom: '15px'
                },

                '& $message': {
                    display: 'block'
                },

                '& $previousPriceLabel': {
                    display: 'block'
                },

                '& $buttonWrapper': {
                    width: '100%'
                },

                '& $button': {
                    width: '100%'
                },

                '& $buttonText': {
                    display: 'block'
                },

                '& $buttonIcon': {
                    display: 'none'
                },

                '& $mobileMessage': {
                    display: 'none'
                }
            }
        },
        footerContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 16px',

            [theme.breakpoints.down(smBreakpoint)]: {
                padding: '20px 0'
            },
        },
        paymentTitle: {
            fontSize: '1.25rem',
            fontFamily: 'RobotoBold',
            color: theme.palette.secondary.main,
            [theme.breakpoints.down(mdBreakpoint)]: {
                display: 'none'
            },
        },
        actionsWrapper: {
            display: 'flex',
            cursor: 'pointer'
        },
        couponCodeWrapper: {
            position: 'relative',
            [theme.breakpoints.down(mdBreakpoint)]: {
                display: 'none'
            },
        },
        calculationWrapper: {
            display: 'flex',
            alignItems: 'flex-end',
            [theme.breakpoints.down(mdBreakpoint)]: {
                flexDirection: 'column',
                alignItems: 'flex-start'
            },
        },
        fixedCalculationWrapper: {
            marginRight: '40px',
            [theme.breakpoints.down(mdBreakpoint)]: {
                marginRight: 0
            },
        },
        monthlyCalculationWrapper: {},
        paymentRoot: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            },
        },
        fixedCalculationRoot: {},
        monthlyCalculationRoot: {},
        couponInputButtonWrapper: {
            display: 'flex',
            border: '1px solid #E6E6E6',
            borderRadius: '4px',
            [theme.breakpoints.down(smBreakpoint)]: {
                display: 'none'
            },
        },
        couponInputButtonWrapperActive: {
            borderColor: theme.palette.primary.main,

            [`& button`]: {
                backgroundColor: theme.palette.primary.main,

                [`& p`]: {
                    color: 'white',
                    opacity: 1
                },

                [`&:focus, &:active, &:hover`]: {
                    backgroundColor: theme.palette.primary.main,
                },
            },

            [theme.breakpoints.down(smBreakpoint)]: {
                display: 'none'
            },
        },
        couponInput: {
            width: '125px',

            [`& fieldset`]: {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                padding: '2px 10px',
                border: 0
            },

            [`& input`]: {
                padding: '16px 10px',
                border: 0
            }
        },
        couponButton: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            width: '90px',
            backgroundColor: 'rgba(51, 51, 51, 0.04)',
            border: 0,

            [`&:focus, &:active, &:hover`]: {
                backgroundColor: 'rgba(51, 51, 51, 0.04)',
            }
        },
        couponButtonText: {
            fontFamily: 'RobotoBold',
            color: theme.palette.secondary.main,
            opacity: 0.5
        },
        couponActive: {},
        button: {
            transition: 'width .3s ease-in-out',
            backgroundColor: theme.palette.primary.main,
            width: '190px',

            '&:hover, &:active, &:focus': {
                backgroundColor: theme.palette.primary.main,

                [`&.Mui-disabled`]: {
                    backgroundColor: theme.palette.primary.main,
                },
            },

            '&:disabled': {
                opacity: 0.5
            },

            [theme.breakpoints.down(xsBreakpoint)]: {
                width: '30px',
                padding: '14px 0'
            },
        },
        buttonText: {
            color: 'white',

            [theme.breakpoints.down(xsBreakpoint)]: {
                display: 'none'
            },
        },
        buttonIcon: {
            color: 'white',
            display: 'none',

            [theme.breakpoints.down(xsBreakpoint)]: {
                display: 'block'
            },
        },
        buttonWrapper: {},
        mainPrice: {
            color: theme.palette.primary.main,
            fontSize: '1.25rem',
            fontFamily: 'RobotoBold',
            marginRight: '2px'
        },
        row: {
            display: 'flex'
        },
        label: {
            fontSize: '1.25rem',
            color: theme.palette.secondary.main,
            fontFamily: 'RobotoBold',
            textTransform: 'uppercase',
            marginRight: '5px'
        },
        secondaryLabel: {
            fontFamily: 'Roboto',
            fontSize: '0.75rem',
            color: '#999999'
        },
        previousPriceLabel: {
            color: theme.palette.secondary.main,
            fontFamily: 'Roboto',
            fontSize: '0.875rem',
            textDecoration: 'line-through',
            opacity: '0.7',
            [theme.breakpoints.down(mdBreakpoint)]: {
                display: 'none'
            },
        },
        message: {
            fontSize: '0.875rem',
            color: theme.palette.secondary.main,
            marginRight: '5px',
            [theme.breakpoints.down(mdBreakpoint)]: {
                display: 'none'
            },
        },
        mobileMessage: {
            [theme.breakpoints.up(mdBreakpoint)]: {
                display: 'none'
            },
        },
        additionalActionWrapper: {
            display: 'flex',
            alignItems: 'center'
        },
        actionCheckbox: {
            paddingLeft: 0
        },
        additionalActionMessage: {
            fontSize: '0.875rem',
            fontFamily: 'RobotoBold',
            color: theme.palette.secondary.main,
            marginRight: '5px'
        },
        additionalActionPrice: {
            fontSize: '0.875rem',
            fontFamily: 'RobotoBold',
            color: theme.palette.primary.main
        },
        expandIcon: {
            transition: 'transform 0.15s ease-out'
        },
        expandIconActive: {
            transform: 'rotate(180deg)',
        },
        removeButton: {
            color: '#D0021B',
            position: 'absolute',
            right: '-30px',
            top: '40%',
            cursor: 'pointer',
            [theme.breakpoints.down(mdBreakpoint)]: {
                right: '0',
            },
        },
        footerActive: {
            '$buttonText': {
                color: 'red',
            }
        }
    }),
)

export default useStyles

import React, {useContext, useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import useStyles from '../Notification/NotificationStyles'
import Notification from '../Notification/Notification'
import AppRoutes from '../../../routes/Routes'
import Footer from '../../common/Footer/Footer'
import SuccessButton from '../Notification/components/SuccessButton'
import FailButton from '../Notification/components/FailButton'
import {QuoteContext} from '../../../services/quote/QuoteProvider'

const SUCCESS = 'success'
const FAIL = 'fail'

const Layout = () => {

    const classes = useStyles()
    const {quote} = useContext(QuoteContext)
    const isNotificationVisible = quote.quoteNotification === SUCCESS || quote.quoteNotification === FAIL

    useEffect(() => {
        window.onbeforeunload = () => {
            const eventName = window.location.hash.includes('form') ? 'web-quotes-slo-last-step' : 'web-quotes-slo-first-step'
            window.dataLayer.push({
                event: eventName
            })
        }
    }, [])

    const getNotificationParams = (type: string) => {
        let data = null
        switch (type) {
            case SUCCESS:
                data = {
                    title: 'successTitle',
                    icon: <CheckCircleOutlineIcon className={classes.successIcon}/>,
                    button: <SuccessButton/>,
                    message: 'successMessage'
                }
                break
            case FAIL:
                data = {
                    title: 'failTitle',
                    icon: <HighlightOffIcon className={classes.failIcon}/>,
                    button: <FailButton/>,
                    message: 'failMessage'
                }
                break
            default:
                break
        }

        return data
    }

    const goToHomePage = () => {
        window.location = 'https://www.marketino.si/cenik/' as any
    }

    const notificationParams = getNotificationParams(quote.quoteNotification || '')

    return (
        <>
            <CloseIcon className="close-icon" onClick={goToHomePage}/>
            {(isNotificationVisible && notificationParams) ? (
                <Notification title={notificationParams.title}
                              icon={notificationParams.icon}
                              message={notificationParams.message}
                              button={notificationParams.button}/>
            ) : (
                <>
                    <Routes>
                        {AppRoutes.map((route, i) => (
                            <Route key={i} {...route} />
                        ))}
                    </Routes>
                    <Footer/>
                </>
            )}
        </>
    )
}

export default Layout

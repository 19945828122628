import React, {useContext} from 'react'
import {Typography} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import {useNavigate} from 'react-router';
import useStyles from '../NotificationStyles'
import I18nContext from '../../../../services/I18n/I18nContext'
import {ActionData, QuoteAction, QuoteContext} from '../../../../services/quote/QuoteProvider'

const FailButton = () => {

    const classes = useStyles()
    const {dispatch} = useContext(QuoteContext)
    const {translate} = useContext(I18nContext)
    const navigate = useNavigate();

    const onButtonClick = () => {
        dispatch({
            data: {quoteNotification: ''} as ActionData,
            type: QuoteAction.setQuoteNotification,
        })
        navigate('/')
    }

    return (
        <Typography className={classes.button} onClick={onButtonClick}>
            <RefreshIcon className={classes.buttonIcon}/>
            {translate('tryAgain')}
        </Typography>
    )
}

export default FailButton


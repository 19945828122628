import React, {useContext} from 'react'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CircleChecked from '@material-ui/icons/CheckCircleOutline'
import {Checkbox, Typography} from '@material-ui/core'
import useStyles from '../FooterStyles'
import I18nContext from '../../../../services/I18n/I18nContext'
import {Quote} from '../../../../services/quote/QuoteProvider'
import {currency} from '../../../containers/Services/components/ServiceItem'


interface IMonthlyCalculationProps {
    showAdditionalFooter: boolean,
    quote: Quote
}

const MonthlyCalculation = (props: IMonthlyCalculationProps) => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)
    const {quote} = props
    const message = quote.totalMonthly > 0 ? translate('monthlyInfoMessage') : translate('noService')
    const deviceItem = quote.items.find(i => i.id === 'device_one')
    const basicService = quote.items.find(i => i.id === 'basicService1')
    const basicServicePrice = basicService && (basicService.price * (deviceItem ? deviceItem.quantity : 1))

    return (
        <div className={classes.fixedCalculationRoot}>
            <div className={classes.row}>
                <Typography className={classes.label}>
                    {translate('monthly')}
                </Typography>
                {
                    quote.totalMonthly > 0 && (
                        <Typography className={classes.mainPrice}>
                            {quote.totalMonthly.toFixed(2)} {currency}/mj
                        </Typography>
                    )
                }
                {
                    quote.totalMonthly > 0 && (
                        <Typography className={classes.secondaryLabel}>
                            {translate('pdv')}
                        </Typography>
                    )
                }
            </div>
            <div className={classes.row}>
                <Typography className={classes.message}>
                    {message}
                </Typography>
            </div>
            {props.showAdditionalFooter && (
                <>
                    {basicService && (
                        <div className={classes.row}>
                            <Checkbox
                                className={classes.actionCheckbox}
                                color="primary"
                                checked={true}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CircleChecked/>}
                            />
                            <div className={classes.additionalActionWrapper}>
                                <Typography className={classes.additionalActionMessage}>
                                    {basicService.title}
                                </Typography>
                                <Typography className={classes.additionalActionPrice}>
                                    {basicServicePrice?.toFixed(2)} {currency}
                                </Typography>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )

}

export default MonthlyCalculation

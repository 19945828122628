import React, {useContext, useEffect, useState} from 'react'
import {ListItemText, Typography} from '@material-ui/core'
import clsx from 'clsx'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import DoneIcon from '@material-ui/icons/Done'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import IService from '../../../../interfaces/IService'
import useStyles from '../ServicesStyles'
import CustomButton from '../../../common/CustomButton/CustomButton'
import {images, imagesMini} from '../images'
import {ActionData, QuoteItem} from '../../../../services/quote/QuoteProvider'
import QuantitySelector from '../../../common/QuantitySelector/QuantitySelector'
import I18nContext from '../../../../services/I18n/I18nContext'
import {mdBreakpoint, windowWidth} from '../../../../utils/constants/constants'

interface IServiceItemProps {
    data: IService,
    selectedItem?: QuoteItem,
    addItem: (item: ActionData) => void,
    removeItem: (item: ActionData) => void,
    changeItem: (item: ActionData) => void,
    resetItem?: boolean
}

export const currency = '€'

const ServiceItem: React.FunctionComponent<IServiceItemProps> = props => {

    const {data, selectedItem} = props
    const classes = useStyles()
    const {translate} = useContext(I18nContext)

    const [selected, setSelected] = useState(false)
    const [quantity, setQuantity] = useState(0);
    const [itemExpanded, setItemExpanded] = useState(false);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const val = event.target.value as number

        if (quantity === 0 && val > 0) {
            props.addItem({...data, quantity: val})
        } else if (val > 0) {
            props.changeItem({...data, quantity: val})
        } else {
            props.removeItem({...data, quantity: 0})
        }

        setQuantity(val)
        setSelected(val > 0)
    };

    const handleSelect = () => {
        if (data.preselected) return
        const isSelected = !selected
        setSelected(isSelected)
        setQuantity(isSelected ? 1 : 0)

        if (isSelected) {
            props.addItem({...data, quantity: 1})
        } else {
            props.removeItem({...data, quantity: 0})
        }
    }

    const toggleItem = () => {
        setItemExpanded(!itemExpanded)
    }

    useEffect(() => {
        if (data.preselected) {
            props.addItem({...data, quantity: 1})
            setSelected(true)
        } else {
            setQuantity(0)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selectedItem) {
            setQuantity(selectedItem.quantity)
            setSelected(true)
        }
        // eslint-disable-next-line
    }, [selectedItem])

    useEffect(() => {
        if (props.resetItem) {
            setQuantity(0)
            setSelected(false)
        }
        // eslint-disable-next-line
    }, [props.resetItem])

    const formatPrice = (num: Number) => num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const image = itemExpanded ? `url(${images[data.imageIndex]})` : `url(${imagesMini[data.imageIndex]})`

    return (
        <div className={clsx(classes.serviceItem, (itemExpanded && 'item-active'))}>
            <div className={classes.imageWrapper}>
                <div className={classes.image} style={{backgroundImage: image}}>
                </div>
            </div>
            <div className={classes.mainWrapper}>
                <div className={classes.serviceHeader}>
                    <div>
                        <h1 className={classes.serviceItemTitle}>{data.title}</h1>
                        <div className={classes.infoTextWrapper}>
                            {!!data.infoText && (
                                <Tooltip title={data.infoText}
                                         enterTouchDelay={0}
                                         placement={windowWidth >= mdBreakpoint ? 'right-start' : 'bottom-start'}
                                         leaveTouchDelay={3000}
                                         classes={{
                                             tooltip: classes.tooltip,
                                         }}>
                                    <InfoIcon className={classes.infoIcon}>
                                        <Typography
                                            className={classes.serviceItemDescription}>{data.description}</Typography>
                                    </InfoIcon>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>

                <Typography className={classes.serviceItemSubTitle}>{data.subtitle}</Typography>
                <div className={classes.serviceItemDescription} dangerouslySetInnerHTML={{
                    __html:
                    data.description
                }}/>
                {!!data.note && <Typography className={classes.serviceItemDescription}>* {data.note}</Typography>}
            </div>
            <div className={classes.actionWrapper}>
                <div className={classes.priceButtonWrapper}>
                    <div className={classes.priceWrapper}>
                        {data.previousPrice && (
                            <ListItemText
                                className={classes.previousPrice}>{formatPrice(data.previousPrice)} {currency}</ListItemText>
                        )}
                        <ListItemText>
                            <Typography className={classes.price}>
                                {formatPrice(data.price)}
                            </Typography>
                            <Typography className={classes.currency}>
                                {currency}{data.monthlyService && '/mj'}
                            </Typography>
                        </ListItemText>
                    </div>
                    <ListItemText className={classes.toggleButton} onClick={toggleItem}>
                        <Typography className={classes.toggleButtonText}>
                            {itemExpanded ? '-' : '+'}
                        </Typography>
                        <Typography className={classes.toggleButtonText}>
                            {translate(itemExpanded ? 'less' : 'more')}
                        </Typography>
                    </ListItemText>
                </div>
                <div className={classes.actionContainer}>
                    {(!data.preselected && !data.hasQuantity && selected) && (
                        <CloseIcon className={classes.removeButton} onClick={handleSelect}/>
                    )}
                    {data.hasQuantity && (
                        <QuantitySelector quantity={quantity}
                                          handleChange={handleChange}
                                          minQuantity={data.preselected ? 1 : 0}
                                          maxQuantity={10}/>
                    )}
                    <CustomButton onClick={handleSelect}
                                  icon={selected ? (
                                      <DoneIcon className={classes.addedIcon}/>
                                  ) : (
                                      <AddIcon className={classes.addIcon}/>
                                  )}
                                  className={(selected ? classes.buttonActive : '') + ' ' + (data.hasQuantity && classes.noButtonRadius)}
                                  disabled={false}
                                  text={translate(selected ? 'added' : 'add')}/>
                </div>
            </div>
        </div>
    )
}

export default ServiceItem

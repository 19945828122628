import React from 'react'
import {ListItem, Typography} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import IServices from '../../../../interfaces/IServices'
import IService from '../../../../interfaces/IService'
import ServiceItem from './ServiceItem'
import useStyles from '../ServicesStyles'
import {ActionData, devices, Quote} from '../../../../services/quote/QuoteProvider'
import {mdBreakpoint, windowWidth} from '../../../../utils/constants/constants'

interface IServiceCategoryItemProps {
    data: IServices,
    serviceNumber: number,
    quote: Quote,
    addItem: (item: ActionData) => void,
    removeItem: (item: ActionData) => void,
    changeItem: (item: ActionData) => void
}

const ServiceCategoryItem: React.FunctionComponent<IServiceCategoryItemProps> = props => {
    const {data, addItem, removeItem, changeItem, serviceNumber, quote} = props

    const classes = useStyles()

    return (
        <ListItem className={classes.serviceWrapper}>
            <div className={classes.serviceCategoryHeader}>
                <Typography variant="h1" className={classes.title}>
                    {serviceNumber}. {data.title}
                    {/* {!data.required && <span style={{textTransform: 'lowercase', marginLeft: '5px'}}>
                        ({translate('optional')})
                    </span>}*/}
                </Typography>
                {!!data.infoText && (
                    <Tooltip title={data.infoText}
                             enterTouchDelay={0}
                             leaveTouchDelay={3000}
                             placement={windowWidth >= mdBreakpoint ? 'right-start' : 'bottom-start'}
                             classes={{
                                 tooltip: classes.tooltip,
                             }}>
                        <InfoIcon className={classes.infoIcon}/>
                    </Tooltip>
                )}
            </div>

            <div className={classes.serviceItemWrapper}>
                {
                    (data.data as IService[]).map((service, index) => {
                        const selectedItem = quote.items.find(i => i.id === service.id)
                        const resetItem = !selectedItem && devices.includes(service.id) // Only one device can be selected
                        return (
                            <ServiceItem key={`serviceItem${index}`}
                                         data={service}
                                         selectedItem={selectedItem}
                                         resetItem={resetItem}
                                         addItem={addItem}
                                         changeItem={changeItem}
                                         removeItem={removeItem}/>
                        )
                    })
                }
            </div>
        </ListItem>
    )
}

export default ServiceCategoryItem

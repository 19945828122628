import React, {useContext, useEffect} from 'react'
import {Container, List} from '@material-ui/core'
import {useNavigate} from 'react-router'
import I18nContext from '../../../services/I18n/I18nContext'
import servicesData from '../../../services/data/services.json'
import IServices from '../../../interfaces/IServices'
import ServiceCategoryItem from './components/ServiceCategoryItem'
import useStyles from './ServicesStyles'
import {ActionData, QuoteAction, QuoteContext} from '../../../services/quote/QuoteProvider'
import SetupInfoPopup from '../../common/SetupInfoPopup/SetupInfoPopup'
import IService from '../../../interfaces/IService'
import {useIsMount} from '../../../utils/hooks/useIsMount'
import {currency} from './components/ServiceItem';
import {getGoogleTagItems} from '../../../utils/helpers/helpers'

const Services = () => {
    const {translate} = useContext(I18nContext)
    const classes = useStyles()
    const navigate = useNavigate();
    const {dispatch, quote} = useContext(QuoteContext)
    const options = servicesData.find(s => s.id === 'options') as IServices
    const setupOption = options?.data.find((o): boolean => o.id === 'option1')

    const isMount = useIsMount();

    useEffect(() => {
        if (!isMount) return;
        window.dataLayer.push({ecommerce: null})
        window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
                currency: currency,
                value: quote.totalFixed === 0 ? quote.totalMonthly : quote.totalFixed,
                items: getGoogleTagItems(quote)
            }
        })
        // eslint-disable-next-line
    }, [isMount])

    const addItem = (data: ActionData) => {
        if (data.id === 'option2') { // If "Internet opcija" selected update quantity with device quantity
            const device = quote.items.find((i: IService) => i.id === 'device_one')
            data.quantity = !!device ? device.quantity : data.quantity
        }

        dispatch({
            data,
            type: QuoteAction.addItem,
        })
    }

    const removeItem = (data: ActionData) => {
        dispatch({
            data,
            type: QuoteAction.removeItem,
        })
    }

    const changeItem = (data: ActionData) => {
        dispatch({
            data,
            type: QuoteAction.changeItem,
        })
    }

    const closeSetupInfoPopup = () => {
        dispatch({
            data: {showSetupInfoPopup: false} as ActionData,
            type: QuoteAction.setShowSetupInfoPopup,
        })
        window.scrollTo({top: 0});
        navigate('/form')
    }

    return (
        <Container className={classes.root}>
            <h1 className={classes.rootTitle}>{translate('title')}</h1>
            <List>
                {
                    (servicesData as IServices[]).map((serviceCategory, index) => {
                        return (
                            <ServiceCategoryItem key={`serviceCategoryItem${index}`}
                                                 serviceNumber={index + 1}
                                                 quote={quote}
                                                 data={serviceCategory}
                                                 addItem={addItem}
                                                 changeItem={changeItem}
                                                 removeItem={removeItem}/>
                        )
                    })
                }
            </List>
            {(quote.showSetupInfoPopup && setupOption) &&
            <SetupInfoPopup option={setupOption} addItem={addItem} close={closeSetupInfoPopup}/>}
        </Container>
    )
}

export default Services

import slLang from './lang/slo/all'

interface Itranslations {
    [s: string]: { [s: string]: string }
}

export enum Language {
    SLO = 'slo',
}

export const translations: Itranslations = {
    [Language.SLO]: slLang,
}

import React, {useContext} from 'react'
import {Checkbox, Typography} from '@material-ui/core'
import CircleChecked from '@material-ui/icons/CheckCircleOutline'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import useStyles from '../FooterStyles'
import {Quote} from '../../../../services/quote/QuoteProvider'
import I18nContext from '../../../../services/I18n/I18nContext'
import {currency} from '../../../containers/Services/components/ServiceItem'

interface IFixedCalculationProps {
    showAdditionalFooter: boolean,
    quote: Quote
}

const FixedCalculation = (props: IFixedCalculationProps) => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)
    const {quote} = props
    const deviceSelected = quote.items.findIndex(i => i.id.includes('device')) !== -1
    const deviceItem = quote.items.find(i => i.id === 'device_one')
    const option = quote.items.find(i => i.id === 'option1')
    const optionPrice = option ? option.price : 0
    const deviceFixedPrice = deviceItem && ((deviceItem.price || 0) * deviceItem.quantity)
    const option2 = quote.items.find(i => i.id === 'option2')
    const option2Price = option2 ? (option2.price * option2.quantity) : 0

    return (
        <div className={classes.fixedCalculationRoot}>
            {
                (quote.totalFixedPrevious > 0 && quote.totalFixedPrevious > quote.totalFixed) && (
                    <Typography className={classes.previousPriceLabel}>
                        {quote.totalFixedPrevious.toFixed(2)} {currency}
                    </Typography>
                )
            }
            <div className={classes.row}>
                <Typography className={classes.label}>
                    {translate('now')}
                </Typography>
                {
                    quote.totalFixed > 0 && (
                        <Typography className={classes.mainPrice}>
                            {quote.totalFixed.toFixed(2)} {currency}
                        </Typography>
                    )
                }
                {
                    quote.totalFixed > 0 && (
                        <Typography className={classes.secondaryLabel}>
                            {translate('pdv')}
                        </Typography>
                    )
                }
            </div>
            <div className={classes.row}>
                <Typography className={classes.message}>
                    {translate((quote.totalFixed > 0 && deviceSelected) ? 'savingMessage' : 'noService')}
                </Typography>
                {(quote.totalFixed > 0 && deviceSelected) && (
                    <Typography className={classes.message}>
                        {quote.totalSavings.toFixed(2)} {currency}!
                    </Typography>
                )}
                {(quote.totalFixed === 0 && !deviceSelected) && (
                    <span className={classes.mobileMessage}>
                        {translate('noService')}
                    </span>
                )}
            </div>
            {props.showAdditionalFooter && (
                <>
                    {deviceItem && (
                        <div className={classes.row}>
                            <Checkbox
                                className={classes.actionCheckbox}
                                color="primary"
                                checked={true}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CircleChecked/>}
                            />
                            <div className={classes.additionalActionWrapper}>
                                <Typography className={classes.additionalActionMessage}>
                                    {deviceItem.title}
                                </Typography>
                                <Typography className={classes.additionalActionPrice}>
                                    {deviceFixedPrice?.toFixed(2)} {currency}
                                </Typography>
                            </div>
                        </div>
                    )}

                    {option && (
                        <div className={classes.row}>
                            <Checkbox
                                className={classes.actionCheckbox}
                                color="primary"
                                checked={true}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CircleChecked/>}
                            />
                            <div className={classes.additionalActionWrapper}>
                                <Typography className={classes.additionalActionMessage}>
                                    {option.title}
                                </Typography>
                                <Typography className={classes.additionalActionPrice}>
                                    {optionPrice.toFixed(2)} {currency}
                                </Typography>
                            </div>
                        </div>
                    )}

                    {option2 && (
                        <div className={classes.row}>
                            <Checkbox
                                className={classes.actionCheckbox}
                                color="primary"
                                checked={true}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CircleChecked/>}
                            />
                            <div className={classes.additionalActionWrapper}>
                                <Typography className={classes.additionalActionMessage}>
                                    {option2.title}
                                </Typography>
                                <Typography className={classes.additionalActionPrice}>
                                    {option2Price?.toFixed(2)} {currency}
                                </Typography>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )

}

export default FixedCalculation

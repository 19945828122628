import React, {useState, useContext, useEffect} from 'react'
import {useNavigate} from 'react-router'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import {Container} from '@material-ui/core'
import {useLocation} from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import useStyles from './FooterStyles'
import FixedCalculation from './components/FixedCalculation'
import MonthlyCalculation from './components/MonthlyCalculation'
import CustomButton from '../CustomButton/CustomButton'
import I18nContext from '../../../services/I18n/I18nContext'
import {ActionData, QuoteAction, QuoteContext} from '../../../services/quote/QuoteProvider'
import {mdBreakpoint} from '../../../utils/constants/constants'
import {saveQuote} from '../../../services/api/index'

const Footer = () => {

    const classes = useStyles()

    const navigate = useNavigate();
    const {translate} = useContext(I18nContext)
    const location = useLocation()
    const {dispatch, quote} = useContext(QuoteContext)
    const [showAdditionalFooter, setShowAdditionalFooter] = useState(false)
    const [showApplyQuoteButton, setShowApplyQuoteButton] = useState(false)
    const [footerExpanded, setFooterExpanded] = useState(false)
    const [savingQuote, setSavingQuote] = useState(false)

    const goToForm = () => {
        const setupSelected = quote.items.findIndex(i => i.id === 'option1') !== -1
        if (setupSelected) {
            window.scrollTo({top: 0});
            navigate('/form')
        } else {
            dispatch({
                data: {showSetupInfoPopup: true} as ActionData,
                type: QuoteAction.setShowSetupInfoPopup,
            })
        }
    }

    const handleToggleFooter = () => {
        if (window.innerWidth <= mdBreakpoint) setFooterExpanded(!footerExpanded)
        setShowAdditionalFooter(!showAdditionalFooter)
    }

    const prepareItems = (quote: any) => {
        let finalItems: any = {}

        let itemsCopy = JSON.parse(JSON.stringify(quote.items))

        itemsCopy.sort((a: any, b: any) => a.order - b.order)

        itemsCopy.forEach((item: any) => {
            if (!item.auto_code) return;

            let quantity = item.quantity

            finalItems = {
                ...finalItems,
                [item.auto_code]: quantity
            }
        })

        return finalItems
    }

    const prepareDeliveryAddress = () => {
        const form = quote.form
        if (!form) return
        return {
            da_first_name: form.deliveryFirstName,
            da_last_name: form.deliveryLastName,
            da_phone: form.deliveryPhone,
            da_address: form.deliveryAddress,
            da_city: form.deliveryPlace,
            da_zip: form.deliveryZip
        }
    }

    const prepareQuoteForSave = () => {
        const form = quote.form
        if (!form) return

        const deliveryAddress = form.deliveryAddressChecked ? prepareDeliveryAddress() : {}
        const partner_code = form.partnerCode.length > 0 ? {partner_code: Number(form.partnerCode)} : {}
        const fiscal_number = form.fiscalNumber.length > 0 ? {fiscal_number: form.fiscalNumber} : {}

        return {
            vat_number: form.oib,
            first_name: form.firstName,
            last_name: form.lastName,
            company_name: form.company,
            phone: form.phone,
            email: form.email,
            address: form.address,
            city: form.place,
            zip: form.zip,
            gdpr_consent: form.terms ? 'yes' : 'no',
            newsletter_consent: form.informed,
            delivery_address: form.deliveryAddressChecked ? 'yes' : 'no',
            items: prepareItems(quote),
            ...deliveryAddress,
            ...partner_code,
            ...fiscal_number
        } as any
    }

    const applyQuote = () => {
        setSavingQuote(true)
        saveQuote(prepareQuoteForSave()).then((res) => {
            setSavingQuote(false)

            window.dataLayer.push({ecommerce: null})
            window.dataLayer.push(res)

            dispatch({
                data: {quoteNotification: 'success'} as ActionData,
                type: QuoteAction.setQuoteNotification,
            })

        }).catch(err => {
            setSavingQuote(false)

            dispatch({
                data: {quoteNotification: 'fail'} as ActionData,
                type: QuoteAction.setQuoteNotification,
            })
        })
    }

    useEffect(() => {
        setShowApplyQuoteButton(location.pathname === '/form')
    }, [location])

    return (
        <div className={clsx(classes.root, (footerExpanded && 'footer-active'))}>
            <Container>
                <div className={classes.footerContainer}>
                    <div className={classes.actionsWrapper} onClick={handleToggleFooter}>
                        <ExpandMoreIcon
                            className={clsx(classes.expandIcon, (showAdditionalFooter && classes.expandIconActive))}/>
                        <Typography className={classes.paymentTitle}>
                            {translate('payment')}
                        </Typography>
                    </div>
                    {/*  <div className={classes.couponCodeWrapper}>
                        <CouponCode dispatch={dispatch} quote={quote}/>
                    </div>*/}
                    <div className={classes.calculationWrapper}>
                        <div className={classes.fixedCalculationWrapper}>
                            <FixedCalculation quote={quote} showAdditionalFooter={showAdditionalFooter}/>
                        </div>
                        <div className={classes.monthlyCalculationWrapper}>
                            <MonthlyCalculation quote={quote} showAdditionalFooter={showAdditionalFooter}/>
                        </div>
                    </div>
                    <div className={classes.buttonWrapper}>
                        {showApplyQuoteButton ? (
                            <CustomButton text={translate('applyQuote')}
                                          onClick={applyQuote}
                                          disabled={!quote.isFormValid || quote.totalMonthly < 1 || savingQuote}
                                          icon={<ArrowForwardIosIcon className={classes.buttonIcon}/>}
                                          className={classes.button}
                                          textClass={classes.buttonText}
                            />
                        ) : (
                            <CustomButton text={translate('next')}
                                          onClick={goToForm}
                                          icon={<ArrowForwardIosIcon className={classes.buttonIcon}/>}
                                          className={classes.button}
                                          textClass={classes.buttonText}
                            />
                        )}
                    </div>
                </div>
            </Container>
        </div>
    )

}

export default Footer

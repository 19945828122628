import React from 'react'
import {createMuiTheme, CssBaseline, MuiThemeProvider, Theme} from '@material-ui/core'
import {HashRouter} from 'react-router-dom'
import './style/App.css'
import {GlobalTheme} from './style/GlobalTheme'
import I18nProvider from './services/I18n/I18nProvider'
import {QuoteProvider} from './services/quote/QuoteProvider'
import Layout from './components/containers/Layout/Layout'

const App = () => {

    const theme: Theme = createMuiTheme(GlobalTheme)

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <CssBaseline>
                    <I18nProvider>
                        <QuoteProvider>
                            <HashRouter>
                                <Layout/>
                            </HashRouter>
                        </QuoteProvider>
                    </I18nProvider>
                </CssBaseline>
            </MuiThemeProvider>
        </>
    )
}

export default App

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import TagManager from 'react-gtm-module'
import App from './App'

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID as string
}
TagManager.initialize(tagManagerArgs)

declare global {
    interface Window {
        dataLayer: any
    }
}

const rootElement = document.getElementById("root") as any;
const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);




import React, {createContext, Dispatch, useReducer} from 'react'

const monthlyServicePrice = 14.99
export const devices = ['device_one']

export interface QuoteItem {
    id: string
    title: string
    subtitle: string
    description: string
    price: number
    previousPrice?: number
    monthlyService: boolean
    paymentDelay: boolean
    fixedPrice?: number
    hasQuantity: boolean
    preselected: boolean
    note?: string
    imageIndex: number
    quantity: number
}

export interface ActionData extends QuoteItem {
    coupon?: string,
    form?: Form,
    isFormValid?: boolean,
    showSetupInfoPopup?: boolean,
    quoteNotification?: string | null
}

export interface Form {
    oib: string,
    firstName: string,
    lastName: string,
    company: string,
    phone: string,
    email: string,
    address: string,
    place: string,
    zip: string,
    fiscalNumber: string,
    deliveryOib: string,
    deliveryFirstName: string,
    deliveryLastName: string,
    deliveryCompany: string,
    deliveryPhone: string,
    deliveryEmail: string,
    deliveryAddress: string,
    deliveryPlace: string,
    deliveryZip: string,
    partnerCode: string,
    deliveryAddressChecked: boolean,
    terms: boolean,
    informed: boolean
}

export type Quote = {
    totalFixed: number,
    totalMonthly: number,
    totalFixedPrevious: number,
    totalSavings: number,
    totalMonthlyPrevious: number,
    totalDelay: number,
    items: QuoteItem[],
    coupon?: string,
    form?: Form,
    formDirty: boolean,
    isFormValid?: boolean,
    quoteNotification?: string | null
    showSetupInfoPopup?: boolean
}

const initForm: Form = {
    oib: '',
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    address: '',
    place: '',
    zip: '',
    fiscalNumber: '',
    deliveryOib: '',
    deliveryFirstName: '',
    deliveryLastName: '',
    deliveryCompany: '',
    deliveryPhone: '',
    deliveryEmail: '',
    deliveryAddress: '',
    deliveryPlace: '',
    deliveryZip: '',
    partnerCode: '',
    deliveryAddressChecked: false,
    terms: true,
    informed: true
}

export const initQuote: Quote = {
    totalFixed: 0,
    totalMonthly: 0,
    totalFixedPrevious: 0,
    totalSavings: 0,
    totalMonthlyPrevious: 0,
    totalDelay: 0,
    items: [],
    coupon: '',
    formDirty: false,
    form: initForm,
    isFormValid: false,
    quoteNotification: null,
    showSetupInfoPopup: false
}

interface IQuoteDispatch {
    dispatch: Dispatch<IQuoteAction>
    quote: Quote
}

export enum QuoteAction {
    'addItem',
    'removeItem',
    'changeItem',
    'setCoupon',
    'setForm',
    'setIsFormValid',
    'setQuoteNotification',
    'setShowSetupInfoPopup',
    'setBusinessType'
}

export interface IQuoteAction {
    type: QuoteAction
    data: ActionData
}

export const QuoteContext = createContext({
    quote: initQuote,
    dispatch: () => {
    },
} as IQuoteDispatch)

const getTotal = (items: QuoteItem[]) => {
    let totalFixed = 0
    let totalMonthly = 0
    let totalFixedPrevious = 0
    let totalDelay = 0
    let totalSavings = 0

    const deviceItems = items.filter(i => i.id.includes('device'))
    const deviceItemsCount = deviceItems.reduce((a, b) => {
        return a + b['quantity']
    }, 0) || 1

    if (deviceItemsCount) {
        totalMonthly = totalMonthly + (monthlyServicePrice * (deviceItemsCount - 1))
    }

    for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (!item.monthlyService && !item.paymentDelay) {
            if (item.id === 'option1') {
                totalFixed = totalFixed + (item.price * deviceItemsCount)
                totalFixedPrevious = totalFixedPrevious + ((item.previousPrice || item.price) * deviceItemsCount)
            } else {
                totalFixed = totalFixed + (item.price * item.quantity)
                totalFixedPrevious = totalFixedPrevious + ((item.previousPrice || item.price) * item.quantity)
            }
        }
        if (item.monthlyService && !item.paymentDelay) {
            if (item.id === 'option1') {
                totalMonthly = totalMonthly + (item.price * deviceItemsCount)
            } else {
                totalMonthly = totalMonthly + (item.price * item.quantity)
            }
        }
        if (item.monthlyService && item.paymentDelay) {
            totalDelay = totalDelay + item.price
            totalFixed = totalFixed + ((item.fixedPrice || 0) * item.quantity)
            totalFixedPrevious = totalFixedPrevious + ((item.previousPrice || item.price) * item.quantity)
            totalMonthly = totalMonthly + (item.price * item.quantity)
        }
    }

    totalSavings = totalFixedPrevious - totalFixed;

    return {totalFixed, totalMonthly, totalFixedPrevious, totalDelay, totalSavings}
}

export const QuoteProvider: React.FunctionComponent = ({children}) => {
    const reducer = (quote: Quote, action: IQuoteAction) => {

        let state = quote || initQuote
        const itemIndex = quote.items.findIndex(i => i.id === action.data.id)
        const isDevice = devices.includes(action.data.id)

        switch (action.type) {
            case QuoteAction.addItem:
                let finalItems = [...quote.items]
                if (isDevice) {
                    const secondDevice = devices.find(d => d !== action.data.id)
                    finalItems = finalItems.filter(d => d.id !== secondDevice) // Only one device can be selected
                }
                state = {
                    ...quote,
                    items: itemIndex !== -1 ? finalItems : [...finalItems, action.data]
                }
                break
            case QuoteAction.changeItem:
                if (itemIndex !== -1) quote.items[itemIndex] = action.data
                state = {
                    ...quote,
                    items: quote.items
                }
                break
            case QuoteAction.removeItem:
                state = {
                    ...quote,
                    items: quote.items.filter(i => i.id !== action.data.id)
                }
                break
            case QuoteAction.setCoupon:
                state = {
                    ...quote,
                    coupon: action.data.coupon
                }
                break
            case QuoteAction.setForm:
                state = {
                    ...quote,
                    form: action.data.form,
                    formDirty: true
                }
                break
            case QuoteAction.setIsFormValid:
                state = {
                    ...quote,
                    isFormValid: action.data.isFormValid
                }
                break
            case QuoteAction.setQuoteNotification:
                state = {
                    ...quote,
                    quoteNotification: action.data.quoteNotification
                }
                break
            case QuoteAction.setShowSetupInfoPopup:
                state = {
                    ...quote,
                    showSetupInfoPopup: action.data.showSetupInfoPopup
                }
                break
            default:
                state = {
                    ...quote
                }
                break
        }

        const total = getTotal(state.items)

        return {
            ...state,
            totalFixed: total.totalFixed,
            totalMonthly: total.totalMonthly,
            totalFixedPrevious: total.totalFixedPrevious,
            totalSavings: total.totalSavings,
            totalDelay: total.totalDelay
        }
    }

    const [quote, dispatch] = useReducer(reducer, initQuote)

    return <QuoteContext.Provider value={{dispatch, quote}}>{children}</QuoteContext.Provider>
}

import React, {ReactNode} from 'react'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import useStyles from './CustomButtonStyles'

interface ICustomButtonProps {
    onClick: () => void
    icon?: ReactNode
    text: string
    className?: any
    textClass?: any
    disabled?: boolean
    style?: any
}

const CustomButton: React.FunctionComponent<ICustomButtonProps> = props => {

    const classes = useStyles()
    return (
        <Button
            onClick={props.onClick}
            className={clsx(classes.root, props.className)}
            disabled={props.disabled}
            variant={'text'}
            style={props.style}
        >
            {props.icon}
            <Typography className={clsx(classes.text, props.textClass)}>
                {props.text}
            </Typography>
        </Button>
    )

}

export default CustomButton

import RoutesList from './RoutesList'
import Services from '../components/containers/Services/Services';
import Form from '../components/containers/Form/Form';

const AppRoutes = [
    {
        path: RoutesList.SERVICES,
        exact: true,
        element: <Services />,
    },
    {
        path: RoutesList.FORM,
        exact: true,
        element: <Form />,
    },
]

export default AppRoutes
